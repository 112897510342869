<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
        <v-subheader>Start Number <span class="red--text">*</span></v-subheader>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
        <v-autocomplete
          v-model="start"
          outlined
          dense
          flat
          background-color="white"
          item-text="serialNumber"
          return-object
          :rules="[v => !!v || 'Start is required']"
          @change="onChangeStart"
          :items="taxNumbers"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
        <v-subheader>End Number <span class="red--text">*</span></v-subheader>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
        <v-autocomplete
          v-model="end"
          outlined
          dense
          flat
          background-color="white"
          item-text="serialNumber"
          return-object
          :rules="[v => !!v || 'End is required']"
          @change="onChangeEnd"
          :items="tempTaxNumbers"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12">
        <v-btn class="mr-4" @click="submit" color="primary">
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "skip-tax-number",
  computed: {
    ...mapState("taxNumber", ["taxNumbers"]),
  },
  data: () => ({
    defaultParams: {
      start: null,
      end: null,
    },
    params: {},
    valid: true,
    start: {},
    end: {},
    tempTaxNumbers: [],
  }),
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("taxNumber/skip", this.params)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    clear() {
      this.params = Object.assign({}, this.defaultParams);
      this.tempTaxNumbers = [];
      this.start = null;
      this.end = null;
    },
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("taxNumber/getAll", false)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onChangeStart(val) {
      this.params.start = val.serialNumber;
      this.tempTaxNumbers = this.taxNumbers.filter(x => {
        if (x.id > val.id) {
          return { ...x };
        }
      });
    },
    onChangeEnd(val) {
      this.params.end = val.serialNumber;
    },
  },
  mounted() {
    this.initialize();
    this.params = Object.assign({}, this.defaultParams);
  },
};
</script>

<style></style>
